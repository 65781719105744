@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap");

* {
  font-family: "Roboto", sans-serif;
}

.nav-link {
  color: #a8d470 !important;
}

body {
  margin: 0px !important;
  padding: 0px !important;
}

/* --------------------------- Menubar Section ------------------------ */
.menubar {
  /* background-color: #9e8b7e !important; */
  background-color: #7d9e6b !important;
  padding: 4px !important;
}
.mob_icon {
  height: 13px !important;
  margin-top: 2px !important;
  margin-right: 5px !important;
}
.menubar_txt,
.menubar_txt:hover {
  color: #fff !important;
  font-size: 12px !important;
  text-decoration: none !important;
}
.menubar_txt1 {
  color: #fff !important;
  font-size: 12px !important;
}
.mail_icon {
  height: 13px !important;
  width: 14px !important;
  margin-top: 2px !important;
  margin-left: 20px !important;
  margin-right: 5px !important;
}
.menubar_vb {
  border-right: 1px solid #fff !important;
  margin: 5px 8px 4px 8px !important;
}
.location_icon {
  height: 8px !important;
  width: 12px !important;
  margin-top: 6px !important;
  margin-left: 4px !important;
}
#menubar_col {
  justify-content: flex-end !important;
}


.dropdown-item.active, .dropdown-item:active{
  background-color: #badc8f !important;
}
.owl-carousel .owl-item img{
  cursor: pointer !important;
}
@media screen and (max-width: 600px) {
  .menubar_txt {
    color: #fff !important;
    font-size: 8.8px !important;
  }
  .menubar_txt1 {
    color: #fff !important;
    font-size: 10px !important;
  }
  #menubar_col {
    justify-content: flex-start !important;
    margin-top: 5px !important;
  }
  .mob_icon {
    height: 10px !important;
    margin-top: 2px !important;
  }
  .mail_icon {
    height: 11px !important;
    width: 13px !important;
    margin-top: 2px !important;
  }
  .location_icon {
    height: 6px !important;
    width: 10px !important;
    margin-top: 5px !important;
  }
  .menubar_vb {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
}

@media screen and (min-width: 601px) and (max-width: 768px) {
  .menubar_txt {
    color: #fff !important;
    font-size: 9px !important;
  }
  .menubar_txt1 {
    color: #fff !important;
    font-size: 10px !important;
  }
  .mob_icon {
    height: 10px !important;
    margin-top: 2px !important;
  }
  .mail_icon {
    height: 11px !important;
    width: 13px !important;
    margin-top: 2px !important;
  }
  .location_icon {
    height: 6px !important;
    width: 10px !important;
    margin-top: 5px !important;
  }
}
@media screen and (min-width: 769px) and (max-width: 1024px) {
  .menubar_txt {
    color: #fff !important;
    font-size: 13.4px !important;
  }
}

/* -------------------------------------- Header Section ------------------------ */
.navbar {
  background-color: #f1f7f0 !important;
  box-shadow: 0px 1px 8px #505f4e !important;
}
.navbar-nav .nav-link {
  padding-right: 0 !important;
  padding-left: 0 !important;
}
.header_item {
  display: flex !important;
}
.header_vb {
  border-right: 2px solid #ddd6a5 !important;
  margin: 0px 15px !important;
  margin-top: -16px !important;
  margin-bottom: -16px !important;
}
.header_img {
  margin-left: 8px !important;
  width: 40px !important;
}
#vb1 {
  margin-top: -7px !important;
  margin-bottom: -7px !important;
}
.header_img1 {
  margin-left: 8px !important;
  margin-right: 7px !important;
  margin-top: -4px !important;
}
.header_img2 {
  margin-left: 8px !important;
  margin-right: 7px !important;
  margin-top: -8px !important;
}
.header_txt {
  font-size: 18px !important;
    color: #6d5b4b !important;
    text-align: center !important;
    margin-top: 0px !important;
    margin: 2px 18px 0px 6px;
}
.header_txt1 {
  font-size: 17px !important;
  color: #6d5b4b !important;
  text-align: center !important;
  margin-top: 6px !important;
  font-weight: 400 !important;
}
.search_input {
  border: none;
  border-bottom: 2px solid #a5978b !important;
  border-radius: 0 !important;
  text-align: center;
  color: #6d5b4b !important;
  background-color: transparent !important;
}
.form-control {
  display: block;
  width: 100%;
  border: none !important;
  border-bottom: 1px solid #44762d  !important;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.form-control:focus{
box-shadow: none !important;
}
input[type="search"]:focus {
  border: none !important;
  border-bottom: 2px solid #a5978b !important;
  border-radius: 0 !important;
  text-align: center;
  color: #6d5b4b !important;
  background-color: transparent !important;
  outline: none !important;
  box-shadow: none !important;
}
input[type="text"]:focus {
  outline: none !important;
  box-shadow: none !important;
}
::placeholder {
  color: #c4ac98 !important;
  opacity: 1 !important;
  font-weight: 400 !important;
  font-size: 18px !important;
}
.search_img {
  width: 20px !important;
  height: 20px !important;
  margin-top: 10px !important;
  margin-left: -20px !important;
}
.cart1 {
  display: none !important;
  width: 30px;
}
.search_btn[type="submit"]:focus {
  outline: none !important;
  box-shadow: none !important;
}
.navbar-brand {
  margin-right: 0 !important;
}
#vb_1 {
  margin-top: -27px !important;
  margin-bottom: -27px !important;
  margin-left: 20px !important;
}
#vb_2 {
  /* margin-top: -33px !important; */
  margin-top: -22px !important;

  margin-bottom: -33px !important;
}
.header_user_btn {
  color: #6d5b4b !important;
  padding: 0 !important;
}
.header_user_btn:focus {
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
}
.cart2 {
  width: 30px;
}

@media screen and (max-width: 600px) {
  .cart1 {
    display: block !important;
  }
  .cart2 {
    display: none !important;
  }
  .navbar-light .navbar-toggler-icon {
    width: 20px !important;
    margin-top: -5px !important;
    margin-left: -3px !important;
  }
  .navbar-light .navbar-toggler {
    width: 40px !important;
    height: 30px !important;
    /* margin-right: -30px; */
    margin-left: -10px !important;
  }
  .header_vb {
    display: none !important;
  }
  .header_item {
    /* display: block !important; */
    margin-top: 0px !important;
    border-bottom: 1px solid #a5978b !important;
  }
  .header_item_1 {
    display: flex !important;
  }
  .header_txt {
    font-size: 18px !important;
    color: #6d5b4b !important;
    text-align: left !important;
    margin-left: 20px !important;
    /* margin-top: 6px; */
  }
  /* #txt_1 {
    margin-left: 25px !important;
  }
  #txt_2 {
    margin-left: 16px !important;
  } */
  .User_drop {
    margin: 10px !important;
  }
}

@media screen and (min-width: 601px) and (max-width: 768px) {
  .cart1 {
    display: block !important;
  }
  .cart2 {
    display: none !important;
  }
  .navbar-light .navbar-toggler-icon {
    width: 20px !important;
    margin-top: -5px !important;
    margin-left: -3px !important;
  }
  .navbar-light .navbar-toggler {
    width: 40px !important;
    height: 30px !important;
    /* margin-right: -30px; */
    margin-left: 0px !important;
  }
  .cart {
    margin-left: 350px !important;
  }
  .header_vb {
    display: none !important;
  }
  .header_item {
    display: block !important;
    margin-top: 0px !important;
    border-bottom: 1px solid #a5978b !important;
  }
  .header_item_1 {
    display: flex !important;
  }
  .header_txt {
    font-size: 16px !important;
    color: black !important;
    text-align: left !important;
    margin-left: 20px !important;
    /* margin-top: 6px; */
  }
  /* #txt_1 {
    margin-left: 25px !important;
  }
  #txt_2 {
    margin-left: 16px !important;
  } */
  .User_drop {
    margin: 10px !important ;
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .navbar form {
    margin-left: 10px !important;
    margin-right: 0px !important;
  }
  .header_img {
    margin-left: 8px !important;
    margin-right: 10px !important;
  }
  #vb_1 {
    margin-top: -27px !important;
    margin-bottom: -27px !important;
    margin-left: 10px !important;
  }
}

/* ---------------------------- Carousel Section --------------------*/
.carousel_container {
  margin-top: 30px !important;
}

@media screen and (max-width: 600px) {
  .carousel_container {
    margin-top: 20px !important;
  }
}

/* --------------------------------- Menu Section -------------------------- */
.menu_container {
  margin-top: 30px;
  padding: 20px 0px;
  background-color: #f1f7f0;
}
.menu_heading {
  color: #7f5a33;
  margin-bottom: 20px;
}
.menu_link,
.menu_link:hover {
  color: #7f5a33;
  margin-top: 5px;
  text-decoration: none;
}
.menu_card {
  border-top-left-radius: 10em !important;
  border-top-right-radius: 10em !important;
  border: none;
  box-shadow: 2px 5px 6px -5px #bc9c23;
  margin: 5px;
  height: 100%;
}
.menu_img {
  width: 170px !important;
  border-radius: 6em 6em 0em 0em;
  margin: 0 auto;
  margin-top: 8px;
  height: 117px !important;
}


.owl-carousel .owl-item img {
  display: block;
  width: 100%;
}
.menu_card_heading {
  color: #7f5a33;
  font-size: 14px;
  margin-bottom: 0;
}
.menu_disc {
  color: #7f5a33;
  font-size: 12px;
  margin: 0;
}
.menu_price {
  color: #7f5a33;

}
.menu_add_product_img {
  width: 15% !important;
  height: 30px;
}
.menu_like_product_img {
  width: 17% !important;
}
@media screen and (max-width: 600px) {
  .menu_img {
    width: 130px !important;
    border-radius: 10em;
    margin: 0 auto;
    margin-top: 10px;
    height: 130px !important;
  }
}
@media screen and (min-width: 601px) and (max-width: 768px) {
  .menu_img {
    width: 150px !important;
    border-radius: 10em;
    margin: 0 auto;
    margin-top: 10px;
    height: 150px !important;
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .menu_img {
    width: 150px !important;
    border-radius: 10em;
    margin: 0 auto;
    margin-top: 10px;
    height: 150px !important;
  }
}


/* .menubar {
  background-color: #9E8B7E !important;
  padding: 4px !important;
}
.mob_icon {
  height: 13px !important;
  margin-top: 2px !important;
  margin-right: 5px !important;
}
.menubar_txt,
.menubar_txt:hover {
  color: #fff !important;
  font-size: 12px !important;
  text-decoration: none !important;
}
.menubar_txt1 {
  color: #fff !important;
  font-size: 12px !important;
}
.mail_icon {
  height: 13px !important;
  width: 14px !important;
  margin-top: 2px !important;
  margin-left: 20px !important;
  margin-right: 5px !important;
}
.menubar_vb {
  border-right: 1px solid #fff !important;
  margin: 5px 8px 4px 8px !important;
}
.location_icon {
  height: 8px !important;
  width: 12px !important;
  margin-top: 6px !important;
  margin-left: 4px !important;
}
#menubar_col {
  justify-content: flex-end !important;
}

@media screen and (max-width: 600px) {
  .menubar_txt {
      color: #fff !important;
      font-size: 8.8px !important;
  }
  .menubar_txt1 {
      color: #fff !important;
      font-size: 10px !important;
  }
  #menubar_col {
      justify-content: flex-start !important;
      margin-top: 5px !important;
  }
  .mob_icon {
      height: 10px !important;
      margin-top: 2px !important;
      
  }
  .mail_icon {
      height: 11px !important;
      width: 13px !important;
      margin-top: 2px !important; 
  }
  .location_icon {
      height: 6px !important;
      width: 10px !important;
      margin-top: 5px !important;
  }
  .menubar_vb {
      margin-top: 0px !important;
      margin-bottom: 0px !important;
  }
}

@media screen and (min-width: 601px) and (max-width: 768px) {
  .menubar_txt {
      color: #fff !important;
      font-size: 9px !important;
  }
  .menubar_txt1 {
      color: #fff;
      font-size: 10px !important;
  }
  .mob_icon {
      height: 10px !important;
      margin-top: 2px !important;
  }
  .mail_icon {
      height: 11px !important;
      width: 13px !important;
      margin-top: 2px !important; 
  }
  .location_icon { 
      height: 6px !important;
      width: 10px !important;
      margin-top: 5px !important;
  }
}
@media screen and (min-width: 769px) and (max-width: 1024px) {
  .menubar_txt {
      color: #fff !important;
      font-size: 13.4px !important;
  }
} */
/* ----------------------------- Popular Items Section ------------------------- */
.popular_items_container {
  margin-top: 30px;
  padding: 20px 0px;
  background-color: #f1f7f0;
}
.popular_card {
  width: 97%;
  border: none;
  border-radius: 20px !important;
  padding: 10px 10px 0 10px;
  margin: 5px;
  box-shadow: 2px 5px 6px -5px #bc9c23;
}
.popular_card33 {
  border: none;
  border-radius: 20px !important;
  padding: 10px 10px 0 10px;
  margin: 10px 0;
  box-shadow: 2px 5px 6px -5px #bc9c23;
  width: 100%;
}
.popular_img {
  width: 100% !important;
  height: 130px !important;
  border-radius: 15px !important;
}
.popular_img33 {
  width: 100% !important;
  height: 120px !important;
  border-radius: 15px !important;
}
.popular_card_body {
  padding: 15px 5px !important;
}
.popular_like_product_img {
  width: 15% !important;
  margin-top: -5px;
}
.popular_add_product_img {
  /* width: 14% !important; */
  width: 26px !important;

  height: 26px;
  /* margin-top: 5px; */
  margin-bottom: 0;
}
.popular_add_product_imgss {
  width: 15% ;
  height: 22px;
  /* margin-top: 5px; */
  margin-bottom: 0;
}
#dislike_img {
  display: block;
}
#like_img {
  display: none;
}
@media screen and (max-width: 600px) {
  .popular_img {
    width: 100% !important;
    height: 140px !important;
    border-radius: 15px !important;
  }
  .popular_add_product_imgss {
    width:auto;
    height: 22px;
    /* margin-top: 5px; */
    margin-bottom: 0;
  }
  .popular_card33 {
    width: 100%;
  }
}
@media screen and (min-width: 601px) and (max-width: 768px) {
  .popular_img {
    width: 100% !important;
    height: 140px !important;
    border-radius: 15px !important;
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .popular_img {
    width: 100% !important;
    height: 140px !important;
    border-radius: 15px !important;
  }
}


/* ---------------------------------- login Section --------------------------- */
.login_container {
  background-color: #d2dfd0 !important;
  /* background-image: url("./Components/User/images/Asset\ 10.png"); */
  background-repeat: no-repeat !important;
  background-attachment: fixed !important;
  background-position: center !important;
  padding-top: 170px !important;
  padding-bottom: 120px !important;
}
.login_card {
  width: 400px !important;
  border: none !important;
  border-radius: 20px !important;
  box-shadow: 1px 1px 8px 0px #888888 !important;
}
.lodin_card_body {
  padding: 30px !important;
}
.login_img {
  display: block !important;
  margin-left: auto !important;
  margin-right: auto !important;
  width: 50% !important;
  /* margin-top: -100px !important; */
}
.login_label {
  color: #aa8f75 !important;
  margin: 0 0 0 5px !important;
  font-size: 14px !important;
}
.login_input,
.login_input:focus {
  border: none !important;
  background-color: #eae3da !important;
  border-radius: 6px !important;
  box-shadow: none !important;
  color: #604128 !important;
}
.input-group-text {
  display: flex !important;
  align-items: center !important;
  padding: 0.375rem 0.75rem !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  color: #212529 !important;
  text-align: center !important;
  white-space: nowrap !important;
  background-color: #eae3da !important;
  border: none !important;
  border-radius: 6px !important;
}
.login_btn {
  box-shadow: 1px 1px 8px 0px #d8cc8f !important;
  border-radius: 10px !important;
  color: #604128 !important;
  padding: 7px 20px !important;
  margin-top: 20px !important;
  font-size: 15px !important;
  background-color: #7b9b69 !important;
  color: white !important;
}
.login_btn100 {
  box-shadow: 1px 1px 8px 0px #888888 !important;
  border-radius: 10px !important;
  color: white !important;
  padding: 7px 20px !important;
  margin-top: 20px !important;
  font-size: 15px !important;
  background-color: #604128 !important;
}
.login_btn:focus {
  box-shadow: 1px 1px 8px 0px #888888 !important;
  border-radius: 10px !important;
  color: white !important;
  padding: 7px 20px !important;
  margin-top: 20px !important;
  background-color: #bc9c23 !important;

}
.login_btn:hover {
  transform: scale(1.05) !important;
  color: white !important;
  background-color: #bc9c23 !important;

}

@media screen and (max-width: 600px) {
  .login_container {
    padding-top: 100px !important;
    padding-bottom: 100px !important;
    background-size: 330px 380px !important;
  }
  .login_card {
    width: 250px !important;
    border: none !important;
    border-radius: 20px !important;
    box-shadow: 1px 1px 8px 0px #888888 !important;
  }
  .lodin_card_body {
    padding: 20px 5px !important;
  }
  .login_img {
    display: block !important;
    margin-left: auto !important;
    margin-right: auto !important;
    width: 40% !important;
    /* margin-top: -50px !important; */
  }
  .login_btn {
    box-shadow: 1px 1px 8px 0px #888888 !important;
    border-radius: 10px !important;
    color: #604128 !important;
    padding: 5px 0px !important;
    margin-top: 20px !important;
    font-size: 12px !important;
    width: 90px !important;
  }
  .login_btn:focus {
    box-shadow: 1px 1px 8px 0px #888888 !important;
    border-radius: 10px !important;
    color: #604128 !important;
    padding: 5px 0px !important;
    font-size: 12px !important;
    margin-top: 20px !important;
  }
}

@media screen and (min-width: 601px) and (max-width: 768px) {
  .login_container {
    padding-top: 350px !important;
    padding-bottom: 355px !important;
    background-size: 520px 820px !important;
  }
  .login_img {
    display: block !important;
    margin-left: auto !important;
    margin-right: auto !important;
    width: 40% !important;
    margin-top: -70px !important;
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .login_container {
    padding-top: 525px !important;
    padding-bottom: 525px !important;
    background-size: 600px 1100px !important;
  }
  .login_img {
    display: block !important;
    margin-left: auto !important;
    margin-right: auto !important;
    width: 40% !important;
    margin-top: -70px !important;
  }
}


/* Cart Container */
.cart_container {
  background-color: #F4EDE4 !important;
  padding: 20px !important;
}
.cart_table {
  width: 100% !important;
}
.cart_table tr {
  border-bottom: 2px solid #E5E1DA !important;
}
.cart_table th {
  color: #604128 !important;
  font-weight: normal !important;
}
.cart_table td {
  color: #7f5a33 !important;
  font-weight: normal !important;
  font-size: 18px !important
}
.cart_img_sect {
  padding: 8px !important;
  background-color: #fff !important;
  width: 165px !important;
  height: 148px !important;
  border-radius: 15px !important;
}
.cart_img {
  width: 100% !important;
  height: 100%;
  border-radius: 15px !important;
  
}
.cart_product_detail {
  text-align: left !important;
  font-size: 18px !important;
  margin-left: 15px !important;
}
.cart_product_disc {
  text-align: left !important;
  font-size: 14px !important;
  margin-left: 15px !important;
  margin-top: 10px !important;
  opacity: 0.7 !important;
}
.cart_check_input:checked[type=checkbox] {
  background-image: url('../src/Components/User/images/Asset\ 31.png');
}
.cart_check_input {
  width: 1.5em !important;
  height: 1.5em !important;
  margin-top: 0 !important;
  vertical-align: top !important;
  background-color: #fff !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: contain !important;
  border: 2px solid #934F12 !important;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
  -webkit-print-color-adjust: exact !important;
  color-adjust: exact !important;
  box-shadow: none !important;
  outline: none !important;
}
.cart_check_input:checked, 
.cart_check_input::after {
  background-color: transparent;
  border: none;
  box-shadow: none;
}
.cart_quantity_input {
  width: 60px !important;
  padding: 0 !important;
  height: 25px !important;
  border: none !important;
  border-radius: 10px !important;
  background-color: #7d9e6b57 !important;
  margin-left: -15px !important;
  margin-right: -15px !important;
  z-index: 1 !important;
  text-align: center !important;
  font-size: 14px !important;
  color: #604128 !important;
}
.cart_minus_img {
  width: 25px !important;
  height: 25px !important;
  margin-top: 0px !important;
  z-index: 10 !important;
  position: relative;
}
.cart_plus_img {
  width: 25px !important;
  height: 25px !important;
  margin-top: 0px !important;
  z-index: 10 !important;
}
.cart_remove_btn {
  background-color: #7b9b69 !important;
  color: white !important;
  border-radius: 10px !important;
  box-shadow: 1px 1px 8px 0px #d8cc8f !important;
  margin-top: 80px !important;
  /* margin-left: 100px !important; */
}



.cart_remove_btn:focus {
  background-color: #bc9c23 !important;
  color: white !important;
  border-radius: 10px !important;
  box-shadow: 1px 1px 8px 0px #d8cc8f !important;
  margin-top: 80px !important;
}
.cart_remove_btn:hover {
  transform: scale(1.03) !important;
  color: white !important;
  background-color: #bc9c23 !important;
}
.cart_running_btn {
  background-color: #7b9b69 !important;
  color: white !important;
  border-radius: 10px !important;
  box-shadow: 1px 1px 8px 0px #d8cc8f !important;
  margin-top: 10px !important;
  margin-left: 100px !important;
  margin-bottom: 10px;
}
.cart_running_btn:focus {
  background-color: #fff !important;
  color: #604128 !important;
  border-radius: 10px !important;
  box-shadow: 1px 1px 8px 0px #604128 !important;
  margin-top: 80px !important;
}
.cart_running_btn:hover {
  transform: scale(1.03) !important;
}
.cart_table_1 {
  width: 45% !important;
  margin-top: 0px !important;
  /* border-left: 2px solid #E5E1DA !important; */
}

.row.justify-content-end.table_1_row{
  margin-top: 60px;
}
.cart_table_1 >:not(caption){
  padding: 0.5rem 0rem 0.5rem 2rem !important;
  background-color: var(--bs-table-bg) !important;
  border-bottom-width: 0px !important;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg) !important;
}
.promo_code_input,
.promo_code_input:focus {
  color: #604128 !important;
  background-color: #EAE3DA !important;
  border: 1px solid #DACFC2 !important;
  border-radius: 6px !important;
  padding: 10px !important;
  font-size: 16px !important;
  box-shadow: none !important;
}
.promo_code_btn,
.promo_code_btn:focus {
  background-color: #7b9b69 !important;
  color: #fff !important;
  border-radius: 10px !important;
  padding: 6px 15px !important;
  box-shadow: 1px 1px 3px 0px #d8cc8f !important;
  position: absolute !important;
  right: 10px !important;
  margin-top: -42px !important;
}

.promo_code_btn:hover {
  transform: scale(1.03) !important;
  color: white !important;
  background-color: #bc9c23 !important;
}
.Checkout_txt {
  color: #7f5a33 !important;
  font-size: 22px !important;
}
.Checkout_price {
  color: #7f5a33 !important;
  font-size: 22px !important;
}

.table > :not(:first-child){
  border: none !important;
}
.Checkout_btn,
.Checkout_btn:focus {
  background-color: #7b9b69 !important;
  color: #ffffff !important;
  border-radius: 10px !important;
  padding: 6px 15px !important;
  box-shadow: 1px 1px 3px 0px #d8cc8f !important;
  width: 100% !important;
  font-size: 18px !important;
}
.Checkout_btn:hover {
  transform: scale(1.03) !important;
  color: white !important;
  background-color: #bc9c23 !important;
}

.cart_input,
.cart_input:focus {
  border: none !important;
  background-color: #F4EDE4 !important;
  color: #604128 !important;
  box-shadow: none !important;
  font-size: 18px !important;
  padding-top: 0 !important;
}
.cart_input[readonly] {
  background-color: #F4EDE4 !important;
  opacity: 1 !important;
}

/*   Switch Button */
.switch-button {
  background: #7d9e6b !important;
  border-radius: 30px !important;
  overflow: hidden !important;
  width: 200px !important;
  text-align: center !important;
  font-size: 18px !important;
  letter-spacing: 1px !important;
  color: white !important;
  position: relative !important;
  padding-right: 100px !important;
  margin-top: -10px;
  margin-bottom: 5px;
}
.switch-button:before {
  content: "Delivery" !important;
  position: absolute !important;
  top: 0 !important;
  bottom: 0 !important;
  right: 0 !important;
  width: 100px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  z-index: 3 !important;
  pointer-events: none !important;
}
.switch-button::after {
  color: #604128 !important;
}
.switch-button-checkbox {
  cursor: pointer !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  bottom: 0 !important;
  width: 100% !important;
  height: 100% !important;
  opacity: 0 !important;
  z-index: 2 !important;
}
.switch-button-checkbox:checked + .switch-button-label:before {
  transform: translateX(95px) !important;
  transition: transform 300ms linear !important;
}
.switch-button-checkbox + .switch-button-label {
  position: relative !important;
  padding: 8px 0 !important;
  display: block !important;
  user-select: none !important;
  pointer-events: none !important;
}
.switch-button-checkbox + .switch-button-label:before {
  content: "" !important;
  background: #bfa12e !important;
  
  height: 100% !important;
  width: 90px !important;
  position: absolute !important;
  left: 0 !important;
  top: 0 !important;
  border-radius: 30px !important;
  transform: translateX(0) !important;
  transition: transform 300ms !important;
}
.switch-button-checkbox + .switch-button-label .switch-button-label-span {
  position: relative !important;
}

@media screen and (max-width: 600px) {
  .cart_container {
      background-color: #F4EDE4 !important;
      padding: 20px 0px  !important;
  }
  .cart_img_sect {
      padding: 4px !important;
      background-color: #fff !important;
      width: 45px !important;
      height: 35px !important;
      border-radius: 5px !important;
  }
  .cart_img {
      width: 30px !important;
  }
  .cart_product_detail {
      text-align: left !important;
      font-size: 10px !important;
      margin-left: 5px !important;
  }
  .cart_product_disc {
      text-align: left !important;
      font-size: 8px !important; 
      margin-left: 5px !important;
      margin-top: 4px !important;
      opacity: 0.7 !important;
  }
  .cart_table td {
      color: #604128  !important;
      font-weight: normal  !important;
      font-size: 10px  !important;
      padding: 5px 0px  !important;
  }
  .cart_table th {
      color: #604128  !important;
      font-weight: normal  !important;
      font-size: 9px  !important;
  }
  .cart_table {
      overflow: hidden  !important;
  }
  .cart_quantity_input {
      width: 60px  !important;
      padding: 0  !important;
      height: 25px  !important;
      border: none  !important;
      border-radius: 10px  !important;
      background-color: #E5DDD3  !important;
      margin-left: -15px  !important;
      margin-right: -15px !important;
      z-index: 1 !important;
      text-align: center !important;
      font-size: 10px !important;
      color: #604128 !important;
  }
  .cart_minus_img {
      width: 25px !important;
      height: 25px !important;
      margin-top: 0px !important;
      z-index: 10 !important;
      position: relative;
  }
  .cart_plus_img {
      width: 25px !important;
      height: 25px !important;
      margin-top: 0px !important;
      z-index: 10 !important;
  }
  .cart_check_input {
      width: 1.5em !important;
      height: 1.5em !important;
      margin-top: 0 !important;
  }
  .cart_remove_btn {
      background-color: #fff !important;
      color: #604128 !important;
      border-radius: 5px !important;
      box-shadow: 1px 1px 8px 0px #604128 !important;
      margin-top: 30px !important;
      font-size: 8px !important;
      padding: 2px 5px !important;
      margin-left: 0 !important;
  }
  .cart_running_btn {
    background-color: #fff !important;
    color: #604128 !important;
    border-radius: 5px !important;
    box-shadow: 1px 1px 8px 0px #604128 !important;
    margin-top: 0px !important;
    font-size: 8px !important;
    padding: 2px 5px !important;
    margin-left: 0 !important;
}   
  .cart_table_1 {
      width: 95% !important;
      margin-top: 0px !important;
      /* border-left: 2px solid #E5E1DA !important; */
  }
  .cart_table_1 >:not(caption){
      padding: 0.2rem 0rem 0.2rem 0.5rem !important;
      background-color: var(--bs-table-bg) !important;
      border-bottom-width: 0px !important;
      box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg) !important;
  }
  .promo_code_input,
  .promo_code_input:focus {
      color: #604128  !important;
      background-color: #EAE3DA  !important;
      border: 1px solid #DACFC2  !important;
      border-radius: 6px  !important;
      padding: 8px  !important;
      font-size: 10px  !important;
      box-shadow: none  !important;
  }
  ::placeholder {
      font-size: 9px  !important;
  }
  .promo_code_btn {
      background-color: #7b9b69   !important;
      color: #fff  !important;
      border-radius: 10px  !important;
      padding: 3px 8px  !important;
      box-shadow: 1px 1px 3px 0px #d8cc8f  !important;
      position: absolute  !important;
      right: 15px  !important;
      margin-top: -30px  !important;
      font-size: 12px  !important;
  }
  .Checkout_txt {
      color: #604128  !important;
      font-size: 12px  !important;
  }
  .Checkout_price {
      width: 60px  !important;
  }
  .Checkout_btn {
      background-color: #fff  !important;
      color: #604128  !important;
      border-radius: 6px  !important;
      padding: 3px 15px  !important;
      box-shadow: 1px 1px 3px 0px #604128  !important;
      width: 100%  !important;
      font-size: 12px  !important;
  }
  .table_1_row {
      padding: 0  !important;
  }
  .cart_input {
      font-size: 11px  !important;
      padding: 0px 5px  !important;
  }

  .switch-button {
      background: #d0b898  !important;
      border-radius: 20px  !important;
      overflow: hidden  !important;
      width: 100px  !important;
      text-align: center  !important;
      font-size: 9px  !important;
      letter-spacing: 0px  !important;
      color: #604128  !important;
      position: relative  !important;
      padding-right: 50px  !important;
  }
  .switch-button:before {
      content: "Delivery"  !important;
      position: absolute  !important;
      top: 0  !important;
      bottom: 0  !important;
      right: 0  !important;
      width: 50px  !important;
      display: flex  !important;
      align-items: center  !important;
      justify-content: center  !important;
      z-index: 3  !important;
      pointer-events: none  !important;
  }
  .switch-button::after {
      color: #604128  !important;
  }
  .switch-button-checkbox {
      cursor: pointer  !important;
      position: absolute  !important;
      top: 0  !important;
      left: 0  !important;
      bottom: 0  !important;
      width: 100%  !important;
      height: 100%  !important;
      opacity: 0  !important;
      z-index: 2  !important;
  }
  .switch-button-checkbox:checked + .switch-button-label:before {
      transform: translateX(50px) !important;
      transition: transform 300ms linear !important;
  }
  .switch-button-checkbox + .switch-button-label {
      position: relative !important;
      padding: 5px 0 !important;
      display: block !important;
      user-select: none !important;
      pointer-events: none !important;
  }
  .switch-button-checkbox + .switch-button-label:before {
      content: "" !important;
      background: #fff !important;
      height: 100% !important;
      width: 50px !important;
      position: absolute !important;
      left: 0 !important;
      top: 0 !important;
      border-radius: 20px !important;
      transform: translateX(0) !important;
      transition: transform 300ms !important;
  }
}

@media screen and (min-width: 601px) and (max-width: 768px) {
  .promo_code_btn {
      background-color: #fff !important;
      color: #604128 !important;
      border-radius: 6px !important;
      padding: 5px 8px !important;
      box-shadow: 1px 1px 3px 0px #604128 !important;
      position: absolute !important;
      right: 52px !important;
      margin-top: -42px !important;
      font-size: 16px !important;
  }
  .cart_input {
      font-size: 16px !important;
      padding: 0px 5px !important;
  }
  .Checkout_price {
      width: 90px !important;
  }
  
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .promo_code_btn {
      background-color: #fff !important;
      color: #604128 !important;
      border-radius: 6px !important;
      padding: 5px 8px !important;
      box-shadow: 1px 1px 3px 0px #604128 !important;
      position: absolute !important;
      right: 60px !important;
      margin-top: -42px !important;
      font-size: 18px !important;
  }
}
/* ----------------------- Profile Area ------------------ */

.profile_container {
  padding: 164px;
  background-color: #f4ede4;
  width: 100% !important;
  height: 100% !important;
}
.plus_btn {
  margin-top: "-40px";
  margin-left: "89px";
}
.inner_part {
  background-color: #f7f5f2;
  padding: 50px;
  border-radius: 25px;
  border: 2px solid #ebdece;
}
.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}
.upload-btn-wrapper1 {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.gazette_upload_btn {
  border: 2px solid gray !important;
  color: gray !important;
  background-color: #f0f3f4 !important;
  padding: 8px 20px !important;
  border-radius: 8px !important;
  font-size: 12px !important;
  font-weight: bold !important;
}

.upload-btn-wrapper input[type="file"] {
  font-size: 100px !important;
  position: absolute !important;
  left: 0 !important;
  top: 0 !important;
  opacity: 0 !important;
}
.upload-btn-wrapper1 input[type="file"] {
  font-size: 100px !important;
  position: absolute !important;
  left: 0 !important;
  top: 0 !important;
  opacity: 0 !important;

}
/* .profile_col_1 {
  padding: 45px 0px;
} */
.profile_input {
  color: #9396c0;
  border: 1px solid #604128 !important;
}
.profile_input:focus {
  box-shadow: none;
}
.pf2 {
  background-color: transparent;
  border: 1px solid #ddd6ce;
  border-radius: 10px;
  padding: 7px;
}
.profile_label {
  color: #9396c0;
  font-size: 12px;
  margin-left: 10px;
}
.profile_customer_name {
  margin-top: 110px;
}
.profile_btn {
  width: 100px;
}
.profile_btn:focus {
  box-shadow: none;
}
/* #btn2 {
    display: none;
    float: right;
  } */

.profile-section {
  width: 120px;
  height: 40px;
  margin: auto;
  color: transparent;
  transition: all 0.3s ease;
  display: flex;
  /* left: 118px; */
  bottom: 40px;
  position: relative;
  transition: all 0.3s ease;
  margin-bottom: 100px;
  z-index: 0;
}
/* .profile-section input {
    display: none;
  } */
.profile-section img {
  position: absolute;
  width: 100px;
  height: 100px;
  z-index: -1;
  margin-top: 55px;
  background-color: white;
  border: 2px solid #e9dac9;
  border-radius: 15px;
  /* margin-left: -47px; */
}
.profile-section .-label {
  cursor: pointer;
  height: 80px;
  width: 80px;
  margin: auto;
  margin-top: 50px;
}
.profile-section:hover .-label {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 10000;
  color: #fafafa;
  transition: background-color 0.2s ease-in-out;
  border-radius: 100px;
  margin-bottom: 0;
}
.profile-section span {
  display: inline-flex;
  font-size: 12px;
  margin: 0 2px;
}
.profile_remove_img{
   height: 25px;
    width: 25px;
    cursor:pointer;
}

@media only screen and (max-width: 600px) {
  .profile_container {
    padding: 120px 0px !important;
  }
  .profile_col_1 {
    padding: 20px 0px;
  }
  .plus_btn {
    margin-top: "-40px";
    margin-left: "50px";
  }
  .upload-btn-wrapper {
    margin-right: 105px;
  }
  .upload-btn-wrapper1 {
    margin-right:170px;
  }
  .profile_remove_img{
    height: 25px;
     width: 25px;
     cursor:pointer;
     margin-left: 30px;
 }
}

@media only screen and (min-width: 600px) and (max-width: 768px) {
  .profile_container {
    padding: 150px 0px !important;
  }
  .profile_customer_name {
    margin-top: 110px;
    font-size: 12px;
  }
  .upload-btn-wrapper {
    margin-left: -62px;
  }
}
@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .profile_container {
    padding: 340px 0px !important;
  }
  .upload-btn-wrapper {
    margin-left: -12px;
  }
}
/* -------------------------------- Footer Section ------------------------- */
.footer_container1 {
  background-color: #7d9e6b !important;
  padding: 10px !important;
}
.footer_logo {
  width: 200px !important;
}
.tagline {
  color: #e2bf86 !important;
  margin-top: 10px !important;
  font-size: 16px !important;
}
.footer {
  background-color: #44762d !important;
  padding: 20px !important;
}
.footer_heading {
  color: #f9deb7 !important;
  font-size: 20px !important;
  font-weight: 500 !important;
}
.footer_vb {
  border-left: 3px solid #f9deb7 !important;
  margin: 5px 20px !important;
}
.mrt {
  margin-top: 30px !important;
}
.footer_txt {
  color: #e2bf86 !important;
}
.footer_img {
  height: 35px !important;
}
.footer a {
  text-decoration: none !important;
}
.footer a:hover {
  color: #e2bf86 !important;
}
.footer_img1 {
  margin: 5px !important;
  width: 40px;
}
.footer_bottom {
  margin-top: 30px !important;
  border-top: 1px solid #3f352e !important;
}
.footer_bottom_txt {
  color: #e2bf86 !important;
  font-size: 14px !important;
  padding-top: 5px !important;
  text-align: center;
}
.footer_logo_icon {
  margin-top: -36px;
  width: 200px;
}
.footer_img_div {
  margin-top: 40px !important;
}

@media screen and (max-width: 600px) {
  .footer_heading {
    font-size: 14px !important;
    font-weight: 500 !important;
  }
  .footer_txt {
    color: #e2bf86 !important;
    font-size: 12px;
  }
  .footer_img {
    height: 25px !important;
  }
  .footer_vb {
    border-left: 2px solid #f9deb7 !important;
    margin: 0px 10px !important;
  }
  .footer_logo_icon {
    width: 20% !important;
    margin-top: 20px !important;
  }
  .footer_img_div {
    margin-top: 20px !important;
  }
  .footer_logo {
    width: 180px !important;
  }
  .tagline {
    font-size: 16px !important;
  }
  .footer_heading_div {
    margin-left: -20px;
  }
}

@media screen and (min-width: 601px) and (max-width: 768px) {
  .footer_logo_icon {
    width: 100% !important;
    margin-top: 40px !important;
  }
  .footer_heading {
    font-size: 18px !important;
    font-weight: 500 !important;
  }
  .footer_img1 {
    width: 80% !important;
  }
  .footer_img_div {
    margin-top: 30px !important;
  }
}

/* -------------------------------All Category Section ------------------------------ */
.category_container {
  background-color: #f4ede4 !important;
  padding: 30px 0 !important;
}
.category_row {
  border-bottom: 2px solid #e5e1da !important;
}
.category_row a {
  text-decoration: none !important;
  color: #604128 !important;
}
.hb {
  border-bottom: 4px solid #604128 !important;
  width: 40px !important;
}
.category_card {
  padding: 15px !important;
  border-radius: 20px !important;
  margin-top: 30px !important;
  box-shadow: 2px 2px 4px #e5d5bf !important;
  background-color: #f7f5f2;
}
.category_card_img {
  padding: 10px !important;
  background-color: #fff !important;
  border-radius: 15px !important;
}
.category_title {
  color: #604128 !important;
  font-size: 24px !important;
}
.category_rate_img {
  width: 20px !important;
}
.category_item_price {
  color: #604128 !important;
  font-size: 22px !important;
  font-weight: bold !important;
  margin-top: 50px !important;
}
.category_card .card-body {
  padding: 0px 20px !important;
  margin-top: 10px;
}
.category_dislike_img {
  margin-top: 90px !important;
  width: 35px !important;
}
.category_add_img {
  margin-top: 10px !important;
  width: 30px !important;
}
.hb33 {
  border-bottom: 4px solid #604128 !important;
  width: 55px !important;
  margin-left: 60px !important;
}

@media screen and (max-width: 600px) {
  .category_card {
    padding: 10px !important;
    width: 95%;
    margin: 0 10px;
    margin-top: 15px !important;
  }
  .category_card_img {
    padding: 5px !important;
    background-color: #fff !important;
    border-radius: 15px !important;
    width: 100px !important;
    height: 90px !important;
  }
  .category_title {
    color: #604128 !important;
    font-size: 14px !important;
    margin-left: 30px !important;
  }
  .rate {
    margin-left: 30px !important;
  }
  .category_rate_img {
    width: 10px !important;
  }
  .category_item_price {
    font-size: 14px !important;
    margin-top: 20px !important;
    margin-left: 30px !important;
  }
  .category_dislike_img {
    margin-top: 30px !important;
    width: 22px !important;
  }
  .category_add_img {
    margin-top: 10px !important;
    width: 20px !important;
  }
  .modal_image{
   padding: 20px;
   margin-left: -30px;
  }
}

@media screen and (min-width: 601px) and (max-width: 768px) {
  .category_title {
    color: #604128 !important;
    font-size: 18px !important;
  }
  .category_rate_img {
    width: 12px !important;
  }
  .category_item_price {
    font-size: 16px !important;
    margin-top: 25px !important;
  }
  .category_dislike_img {
    margin-top: 50px !important;
    width: 28px !important;
  }
  .category_add_img {
    margin-top: 10px !important;
    width: 25px !important;
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .category_title {
    color: #d27e0d !important;
    font-size: 20px !important;
  }
  .category_rate_img {
    width: 15px !important;
  }
  .category_item_price {
    font-size: 18px !important;
    margin-top: 45px !important;
  }
  .category_dislike_img {
    margin-top: 65px !important;
    width: 32px !important;
  }
  .category_add_img {
    margin-top: 10px !important;
    width: 28px !important;
  }
}

/* For Loader */
/* .loader_container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;

}
.loader {
  left: 45%;
  top: 30%;
  z-index: 1000;
  position: absolute;
} */
/* For Modal */
.modal-title {
  color: #bb9b22;
}
.modal-body {
  color: #bb9b22;
}
.modal-body p {
  font-size: 18px;
}
.modal-body h2 {
  color: #bb9b22;
  font-weight: 1000;
  font-size: 34px;
}
.addcart {
  background-color: #bb9b22 !important;
  color: white !important;
  font-size: 22px !important;
  font-weight: 600 !important;
  padding: 10px 0 !important;
  border-radius: 13px !important;
  border-color: white !important;
  margin-top: 20px !important;
}
.modal-1 {
  border-radius: 20px !important;
  padding: 15px !important;
}
.modal-header .btn-close {
  margin-top: -40px !important;
}
.modal_image{
  width: 150px !important;
   height: 150px !important;
   border-radius:50% !important;
   margin-top:-90px !important; 
}

/* Address Dropdown */
.delivery-info p {
  color: #d27d0da8;
  font-size: 14px;
  font-weight: 500;
  margin-top: 15px;
  border-bottom: 1px solid #E1DDD6;
  padding-bottom: 4px;
}

#dropdownMenuButton1 {
  padding-right: 1%;
  color: #66432D;
  background-color: #EAE3DA;
}

/* .dropdown-menu li {
  width: 100px;
} */

.dropdown-toggle:focus {
  box-shadow: none;
  outline: none;
}
.dropdown-toggle::after{
  /* display: none !important; */
}
.dropdown-toggle img{
  margin-left: 420px;
}
@media screen and (max-width: 480px){
  .dropdown-menu li {
  width: 280px;
}
.dropdown-toggle img{
  margin-left: 150px;
}
}

.modal-header55{
  border-bottom: none !important;
}
/* Notification page */
.notification_title{
  margin-top: 35px !important;
  margin-left: 20px !important;
}
/* //Delivery Page */

.order-title55{
  margin: 0 120px;
  padding-top: 10px;
  font-size: 14px;
 padding-bottom: 5px;
 color: #704B39;
}
.order-history55 {
  border: none;
}

.card_delivery  {

  margin: 0 120px;
  background-color: #E5E1DA;
  border-radius: 10px;
}

.status55 {
  float: right;
}

.title55 {
  color: #704B39;
  padding-bottom: 12px;
  border-bottom: 1.5px dashed #704B39;
  font-weight: 700;
}

.stat-55 {
  color: #428E66;
}

.order-disc55 {
  color: #704B39;
  font-size: 14px;
  font-weight: 600;

}

.order-disc55 h6 {
  font-size: 21px;
}

.disc55 {
  border-bottom: 1.5px dashed #704B39;
  margin: 0 2px;
  background-color: #E5E1DA;
}

.detail55 {
  margin: 0 2px;
  color: #704B39;
}

.order-span55 {
  font-weight: 700;
}

.order-25 {
  text-align: center;
}

.order-35 {
  text-align: right;
}

@media screen and (max-width: 600px) {
  .card_delivery {

      margin: 0 10px;
      background-color: #E5E1DA;
      border-radius: 10px;
  }
  /* .loader {
    left: 30%;
    top: 35%;
    z-index: 1000;
    position: absolute;
  } */
  .modal-body h2 {
    color: #775a33;
    font-weight: 1000;
    font-size: 20px;
  }
  .title55{
      font-size: 12px;
  }
  .detail55{
      font-size: 12px;
  }
  .order-disc55{
      font-size: 12px;
  }
  .order-disc55 h6 {
  font-size: 18px;
}


}
.shipping55{
  font-size: 12px;
  color: #704B39;
  border-bottom: 1.5px dashed #704B39;
  padding-bottom: 10px;

}
.shipping55 p{
  margin-bottom: -0px;
}
.addr-15{
  margin-left: 70px;
}
.addr-25{
  margin-left: 45px;
}
.addr-35{
  margin-left: 60px;
  
}
@media screen and (max-width: 600px){
  .addr-15{
  margin-left: 0px;
}
.addr-25{
  margin-left: 0px;
}
.addr-35{
  margin-left: 0px;
  
}

}
.prod-img55{
  border: 5px solid #F7F5F2;
  border-radius: 5px;
  height: 150px;
  width: 200px;
  margin-bottom: 10px;
}
.thnaks_imgsss {
  width: 500px;
  margin-top: 40px;
}
@media screen and (max-width: 600px){
  .prod-img55{
      margin-left: -15px;
  height: 80px;
  width: 80px;
  border: 3px solid #F7F5F2;
  border-radius: 5px;
}
.thnaks_imgsss {
  width: 300px;
}
}
.price_to_right{
margin-left:350px;
}
.dropdown-menu.show{
  /* border-color: #F5F2EE !important; */
  /* border: 1p solid gray !important; */
}



/* FOR Contct us*/
.course {
  background-color: #fff;
  border-radius: 10px;
  /* box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2); */
  box-shadow: 0px 0px 15px 0px rgb(0 0 0 / 10%);

  display: flex;
  max-width: 100%;
  margin: auto;
  overflow: hidden;
  width: 900px;
}

/* .course h6 {
  opacity: 0.6;
  margin: 0;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-size: 14px !important;
  color: #fff !important;
}

.course h2 {
  letter-spacing: 1px;
  font-size: 20px !important;
  color: #fff !important;
} */

.course-preview {
  background-color: #44762d;
  color: #fff;
  padding: 30px !important;
  /* width: 500px; */
  font-weight: 700;
}

.course-preview a {
  color: #fff;
  display: inline-block;
  font-size: 12px;
  opacity: 0.6;
  margin-top: 30px;
  text-decoration: none;
}

.course-info {
  padding: 20px 30px !important;
  position: relative;
  /* width: 100%; */
}
.btn_order {
  background-color: #44762d;
  border: 0;
  border-radius: 10px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
  color: #fff;
  font-size: 16px;
  padding: 12px 25px;
  /* position: absolute; */
  bottom: 30px;
  right: 30px;
  letter-spacing: 1px;
  text-decoration: none;
}
.btn_order:hover {
  background-color: #44241c;
  border: 0;
  border-radius: 10px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
  color: #fff;
}

.nav-item .active {
  color: #44762d !important;
}
.foot_linksss{
  color: #e2bf86;
}
.foot_linksss:hover{
  color: blue;
  text-decoration: underline !important;
}

/* FOR Thanku Page  */
.thankyou-wrapper {
  width: 100%;
  height: auto;
  margin: auto;
  background: #ffffff;
  padding: 10px 0px 50px;
}
.thankyou-wrapper h1 {
  /* font: 100px Arial, Helvetica, sans-serif; */
  text-align: center;
  color: #333333;
  padding: 0px 10px 10px;
}
.thankyou-wrapper p {
  font-size: 26px;
  text-align: justify;
  color: #333333;
  padding: 5px 10px 10px;
  font-weight: 600;
}
.thankyou-wrapper a {
  /* font: px Arial,; */
  text-align: center;
  color: #ffffff;
  /* display: block; */
  text-decoration: none;
  /* width: 250px; */
  background: #583026;

  /* margin: 10px auto 0px; */
  padding: 8px 10px;
  /* border-bottom: 5px solid #F96700; */
  border-radius: 5px;
  margin-left: 5px;
}
.thankyou-wrapper a:hover {
  color: #ffffff;

  background: #1d5530;
}
